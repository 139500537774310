import { useQuery } from "react-query";
import { ServerAPI } from "../api";
import { API } from "../services/api";
import { Theme } from "../types/company";

export const useGetInitalRequest = (branchId: string) => {
  return useQuery<{ message: string }, { error: { message: string } }, Theme>(
    ["get-meta-data", branchId],
    async () => await API.getter(ServerAPI.metaData(branchId))
  );
};
