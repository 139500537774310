import { useQuery } from "react-query";
import { ServerAPI } from "../../api";
import { API } from "../../services/api";

export const useGetDeviceByMAC = (macAddress: string) => {
  return useQuery<
    { message: string },
    { error: { message: string } },
    {
      device?: {
        id: string;
        MAC_Address: string;
        operatingSystem?: string;
        type?: string;
        userId: string;
        end_user: {
          firstName: string;
          lastName: string;
          countryCode: string;
          phoneNumber: string;
          password: string;
        };
      };
    }
  >(
    ["get-device-data", macAddress],
    async () => await API.getter(ServerAPI.deviceByMac(macAddress))
  );
};
