const locales = {
  welcome: "Welcome to WinFi",
  createAnAccount: "Create an account or",
  orLogin: "login",
  firstName: "First name",
  lastName: "Last name",
  countryCode: "Country code",
  phone: "Phone number",
  email: "Email address",
  createPassword: "Create password",
  signup: "Sign Up",
  byClicking:
    'By clicking the "Sign Up" button, you are creating a WinFi account, and you agree to WinFi’s',
  terms: "Terms of Use",
  and: "and",
  privacy: "Privacy Policy.",
  recommendBusinessEmail: "We recommend using your business email",
  aminimumOf10Characters: "A minimum of 10 characters",
  atLeastOneNumber: "At least one number",
  atLeastOneLowercaseLetter: "At least one lowercase letter",
  atLeastOneUppercaseLetter: "At least one uppercase letter",
  show: "Show",
  hide: "Hide",
  isRequired: "is required",
  wrongPassword: "Make sure your password includes the following:",
  hangTightLoading: "Hang tight! We are signing you up!",
  emailShouldBeValid: "Please enter a valid email format",
  wrongPhoneFormat: "Please enter a valid phone number format",
};

export default locales;
