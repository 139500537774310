import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const themeState = atom<any>({
  key: "theme",
  default: sessionStorage.getItem(localstorageKeys.theme)
    ? JSON.parse(sessionStorage.getItem(localstorageKeys.theme) as string)
    : undefined,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((theme: any) => {
        if (theme) {
          sessionStorage.setItem(localstorageKeys.theme, JSON.stringify(theme));
        }
      });
    },
  ],
});
