import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const loginLinkState = atom<string | undefined>({
  key: "loginLink",
  default:
    (sessionStorage.getItem(localstorageKeys.loginLink) as string) ?? undefined,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((loginLink: string | undefined) => {
        if (loginLink) {
          sessionStorage.setItem(localstorageKeys.loginLink, loginLink);
        }
      });
    },
  ],
});
