import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  styled,
  Link,
} from "@mui/material";
import { Logo } from "../../components/Logo";
import qs from "qs";
import { useGetInitalRequest } from "../../queries/inital";
import { themeState } from "../../store/theme";
import { serviceState } from "../../store/service";
import { useGetDeviceByMAC } from "../../queries/auth/device";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router";
import { branchIdState } from "../../store/branch";
import { macState } from "../../store/mac";
import WinFiLogo from "../../assets/winfi-logo.png";
import { userState } from "../../store/user";
import { loginLinkState } from "../../store/login-link";
import Lottie from "react-lottie";
import * as animationData from "../../assets/WinFi loading.json";
import { deviceState } from "../../store/device";
import { companyFeaturesState } from "../../store/comapny-features";

const ProcessingPage: React.FC = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [companyFeatures, setCompanyFeatures] =
    useRecoilState(companyFeaturesState);
  const formRef: any = useRef(null);

  const [service, setService] = useRecoilState(serviceState);
  const [branchId, setBranchId] = useRecoilState(branchIdState);
  const [mac, setMac] = useRecoilState(macState);
  const [user, setUser] = useRecoilState(userState);
  const [device, setDevice] = useRecoilState(deviceState);
  const [loginLink, setLoginLink] = useRecoilState(loginLinkState);
  const [readyForAutoConnect, setReadyForAutoConnect] =
    useState<boolean>(false);

  const [builderTheme, setTheme] = useRecoilState(themeState);
  const queryParams = qs.parse(
    decodeURIComponent(window.location.search) ?? window.location.search,
    {
      ignoreQueryPrefix: true,
    }
  );

  const { status, data } = useGetInitalRequest(queryParams.branchId as string);

  useEffect(() => {
    setCompanyFeatures(data?.companyFeatures);
  }, [data]);

  const { status: deviceStatus, data: deviceData } = useGetDeviceByMAC(
    queryParams.mac as string
  );

  const checkConnectionAndRedirect = (url: string, autoConnect?: boolean) => {
    if (queryParams?.error) {
      history.push(
        `/service/connect/error?message=${queryParams?.error}&from=${window.location.href}`
      );
    } else {
      if (autoConnect) {
        setReadyForAutoConnect(true);
      } else {
        history.push(url);
      }
    }
  };

  useEffect(() => {
    if (readyForAutoConnect && loginLink && user?.phoneNumber) {
      formRef.current.submit();
    }
  }, [readyForAutoConnect, loginLink, user]);

  useEffect(() => {
    if (status === "success") {
      if (data?.builderAttributes) {
        setTheme(data?.builderAttributes);
      } else {
        setTheme({
          logo: WinFiLogo,
          primaryColor: "#101928",
          buttonTextColor: "#fff",
          messageTitle: "Welcome to our WiFi",
          messageSubtitle:
            "Enjoy a fast reliable internet connection powered by WinFi",
        });
      }

      setBranchId(queryParams?.branchId as string);
      setMac(queryParams?.mac as string);
      setLoginLink(queryParams["link-login-only"] as string);
      setService(data?.serviceStatus);
    } else if (status === "error") {
      checkConnectionAndRedirect("/service/disabled");
    }
  }, [status]);

  useEffect(() => {
    if (deviceStatus === "error") {
      checkConnectionAndRedirect("/auth/login");
    }

    if (
      service === "enabled" ||
      service === "disabled" ||
      data?.serviceStatus === "enabled" ||
      data?.serviceStatus === "disabled"
    ) {
      if (
        deviceStatus === "success" &&
        (data?.serviceStatus === "enabled" || service === "enabled")
      ) {
        if (deviceData?.device) {
          setDevice(deviceData?.device);
          setUser(deviceData?.device?.end_user);
          checkConnectionAndRedirect(
            `/service/connect?user=${deviceData.device.userId}&branch=${queryParams?.branchId}`,
            builderTheme?.autoConnectUsers
          );
        } else {
          checkConnectionAndRedirect("/auth/login");
        }
      }
    }
  }, [deviceStatus, service]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const CustomLink = styled(Link)({
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
    textDecorationColor: theme.palette.text.primary,
  });

  return (
    <Box
      p={2}
      mt={isSmDown ? 12 : 0}
      ml={isSmDown ? 1 : 0}
      mr={isSmDown ? 1 : 0}
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems={"flex-start"}
    >
      {isSmDown && (
        <Box
          sx={[
            {
              display: "flex",
              position: isSmDown ? "absolute" : "relative",
              top: "0px",
              alignItems: "center",
              p: 1,
              bgcolor: "#eff7ff",
              borderRadius: "4px",
              mb: 2,
              height: "36px",
            },
            isSmDown
              ? { width: "100%", right: "0px", justifyContent: "center" }
              : {},
          ]}
        >
          <img
            src={require("../../assets/logo.webp")}
            style={{
              height: "20px",
              marginInlineEnd: "4px",
            }}
            alt="logo"
          />
          <Typography variant={"caption"} fontWeight={400} color="text.primary">
            Powered & secured by our partners at{" "}
            <CustomLink
              href="https://winfico.com/"
              target="_blank"
              variant={"body2"}
            >
              WinFi
            </CustomLink>
          </Typography>
        </Box>
      )}

      {loginLink && user?.phoneNumber && (
        <form ref={formRef} name="login" action={loginLink} method="post">
          <input
            type="hidden"
            name="username"
            value={`${user?.countryCode.split("+")[1]}${user?.phoneNumber}`}
          />
          <input
            type="hidden"
            name="password"
            value={`${user?.phoneNumber}@${user?.gender}`}
          />
        </form>
      )}

      <Box>
        <Lottie options={defaultOptions} height={200} width={200} />
        {readyForAutoConnect && (
          <>
            <Typography textAlign={"center"} variant="h5">
              Automatically connecting...
            </Typography>
            <Typography textAlign={"center"} variant="body2" sx={{ mt: 1 }}>
              No need to click on connect button everyday, we are doing the job
              for you..
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProcessingPage;
