import { baseAxiosInstance } from "../../api/axios";
import { AxiosRequestConfig } from "axios";

export const poster = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => {
  try {
    const response = await baseAxiosInstance.post(url, data, config);
    return response.data;
  } catch (error: any) {
    if (error.response?.data) {
      throw error.response?.data;
    } else {
      throw new Error("something went wrong");
    }
  }
};
