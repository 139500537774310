import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const userState = atom({
  key: "user",
  default: JSON.parse(
    sessionStorage.getItem(localstorageKeys.user) ?? "{}"
  ) as any,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((user) => {
        sessionStorage.setItem(localstorageKeys.user, JSON.stringify(user));
      });
    },
  ],
});
