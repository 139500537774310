import login from "./auth/login/ar";
import forgotPassword from "./auth/forgot-password/ar";
import resetPassword from "./auth/reset-password/ar";
import register from "./auth/register/ar";

const locales = {
  auth: {
    login,
    forgotPassword,
    resetPassword,
    register,
  },
};

export default locales;
