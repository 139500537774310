import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const branchIdState = atom<string | undefined>({
  key: "branchId",
  default:
    (sessionStorage.getItem(localstorageKeys.branchId) as string) ?? undefined,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((branchId: string | undefined) => {
        if (branchId) {
          sessionStorage.setItem(localstorageKeys.branchId, branchId);
        }
      });
    },
  ],
});
