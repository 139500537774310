import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";
import { companyFeatures } from "../types/company";

export const companyFeaturesState = atom<companyFeatures | undefined>({
  key: "companyFeatures",
  default: JSON.parse(
    sessionStorage.getItem(localstorageKeys.companyFeatures) ?? "{}"
  ) as any,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((companyFeatures: any | undefined) => {
        if (companyFeatures) {
          sessionStorage.setItem(localstorageKeys.companyFeatures, JSON.stringify(companyFeatures));
        }
      });
    },
  ],
});